<template>
    <SinglePayment/>
</template>
<script>
import SinglePayment from '@/components/Wire/Payments/PaymentDetails'
export default {
    components:{
        SinglePayment
    }
}
</script>